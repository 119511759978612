import React, { useEffect} from 'react'
import { createPortal } from "react-dom"
import styles from './Action.module.scss'
import {ModalOverlay} from '../ModalOverlay/ModalOverlay'
import logo from '../../image/Header/logo.svg'

const Action = ({onClose}) => {

    const modalRoot = document.getElementById("modals");

    useEffect(() => {
        const handleEscClose = (evt) =>{
            if (evt.key ==='Escape')  onClose()
        }
        document.addEventListener('keydown', handleEscClose)
        return () => document.removeEventListener('keydown', handleEscClose)
    }, [onClose])

    return createPortal (
        <ModalOverlay onClick={onClose}>
            <div className={styles.modal} onClick={evt => evt.stopPropagation()}>
                <div className={styles.box_logo}>
                    <img className={styles.logo} src={logo} alt='логотип'/>
                    <button data-test="close-button" className={styles.close_icon} type="button" aria-label="закрыть" onClick={onClose}/>
                </div>
                <div className={styles.box}>
                    <h2 className={styles.title}>БОЛЬШЕ ЗАКАЗОВ-БОЛЬШЕ СКИДКА</h2>
                    <p className={styles.subtitle}> Закажи вывоз жбо от 5м³ в период с 1&#160;апреля по 31 мая и получи скидку:</p>
                    <p className={styles.text}>СКИДКА НА 1 ЗАКАЗ - <span>100&#8381;</span></p>
                    <p className={styles.text}>СКИДКА НА 2 ЗАКАЗ - <span>200&#8381;</span></p>
                    <p className={styles.text}>СКИДКА НА 3 ЗАКАЗ - <span>300&#8381;</span></p>
                    <p className={styles.text}>СКИДКА НА 4 ЗАКАЗ - <span>400&#8381;</span></p>
                    <p className={styles.text}>СКИДКА НА 5 ЗАКАЗ - <span>500&#8381;</span></p>
                    <p className={styles.subtitle}>ПРОМОКОД "ВЕСНА"</p>
                </div>
            </div>
        </ModalOverlay>
        ,modalRoot
    );
};

export default Action